<template>
  <div>
    <div class="mb-2 label-header">Member Information</div>

    <b-row class="customer-more-detail">
      <b-col md="4" sm="6" lg="3">
        <div>Member Tier</div>
        <div class="line-height-1">
          <div class="text-black">{{ form.member_level_name }}</div>
          <small class="text-gray">
            Start: {{ $moment(form.tier_start_date).format("DD/MM/YYYY") }}
            {{ countDuration("tier_start_date") }} </small
          ><br />
          <small class="text-gray">
            Expire:
            {{
              form.expire_date
                ? $moment(form.expire_date).format("DD/MM/YYYY")
                : "-"
            }}
            {{ countDuration("expire_date") }}
          </small>
        </div>
      </b-col>

      <b-col md="4" sm="6" lg="3">
        <div>Price Tier</div>
        <div>-</div>
      </b-col>

      <b-col md="4" sm="6" lg="3">
        <div>Point</div>
        <div class="line-height-1 text-black">
          <div>
            <span class="text-black">
              {{ form.point_total | numeral("0,0") }}
            </span>
            <b-button
              variant="link"
              class="text-warning px-1 py-0"
              style="height: auto"
              @click="pointModal()"
            >
              <font-awesome-icon icon="pencil-alt" class="f-12" title="Edit" />
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div>Credit</div>
        <div class="line-height-1">
          <div class="text-black">-</div>
        </div>
      </b-col>

      <b-col md="4" sm="6" lg="3" v-if="form.is_company == 1">
        <div>Document</div>
        <div>
          {{ form.documents.filter((el) => el.path).length }}/{{
            documentsCount()
          }}
          <b-button
            variant="link"
            class="text-warning px-1 py-0"
            @click="showDocument()"
          >
            <font-awesome-icon icon="pencil-alt" title="Edit" />
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr />
    <div class="mb-2 label-header">Purchase Information</div>
    <b-row class="customer-more-detail pb-4">
      <b-col md="4" sm="6" lg="3">
        <div>Total Spending</div>
        <div>{{ form.grand_total | numeral("0,0") }} Baht</div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div>Customer Lifecycle</div>
        <div>{{ form.customer_lifecycle || "-" }}</div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div>First Purchase Date</div>
        <div>{{ form.first_purchase_date || "-" | moment("DD/MM/YYYY") }}</div>
      </b-col>

      <b-col md="4" sm="6" lg="3">
        <div>Last Purchase Date</div>
        <div>
          {{ form.last_purchase_date || "-" | moment("DD/MM/YYYY") }}
        </div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div>First Purchase Branch</div>
        <div>{{ form.first_purchase_branch || "-" }}</div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div>First Purchase Channel</div>
        <div>{{ form.first_purchase_channel || "-" }}</div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div>Last Purchase Branch</div>
        <div>
          <!-- <pre>{{ form }}</pre> -->
          {{ form.last_purchase_branch || "-" }}
        </div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div>Last Purchase Channel</div>
        <div>{{ form.last_purchase_channel || "-" }}</div>
      </b-col>

      <div class="break-normal"></div>
      <b-col md="4" sm="6" lg="3">
        <div class="mb-2">Recency</div>
        <div class="content-between">
          <small>MIN </small> <small>MAX</small>
        </div>
        <div class="range-wrap">
          <div class="range-value" id="rangeV"></div>
          <div
            class="range-avg"
            id="range-recen-avg"
            :avg="form.recently.average"
          ></div>
          <!-- {{ form.recently }} -->
          <input
            id="range"
            type="range"
            :value="form.recently.value"
            :max="form.recently.max"
            :min="
              form.recently.value < form.recently.min ? 0 : form.recently.min
            "
            :data-avg="form.recently.average"
            step="0.1"
            disabled
          />
        </div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div class="mb-2">Frequency</div>
        <div class="content-between">
          <small>MIN </small> <small>MAX</small>
        </div>
        <div class="range-wrap">
          <div class="range-value" id="rangeV-freq"></div>
          <div
            class="range-avg"
            id="range-freq-avg"
            :avg="form.frequency.average"
          ></div>
          <input
            id="range-freq"
            type="range"
            :value="form.frequency.value"
            :max="form.frequency.max"
            :min="
              form.frequency.value < form.frequency.min ? 0 : form.frequency.min
            "
            :data-avg="form.frequency.average"
            step="0.1"
            disabled
          />
        </div>
      </b-col>
      <b-col md="4" sm="6" lg="3">
        <div class="mb-2">Monetary</div>
        <div class="content-between">
          <small>MIN </small> <small>MAX</small>
        </div>
        <div class="range-wrap">
          <div class="range-value" id="rangeV-mon"></div>
          <div
            class="range-avg"
            id="range-mon-avg"
            :avg="form.monetary.average"
          ></div>
          <input
            id="range-mon"
            type="range"
            :value="form.monetary.value"
            :max="form.monetary.max"
            :min="
              form.monetary.value < form.monetary.min ? 0 : form.monetary.min
            "
            :data-avg="form.monetary.average"
            step="0.1"
            disabled
          />
        </div>
      </b-col>
    </b-row>

    <template
      v-if="form.user_guid && $store.state.permission.Permission.includes(59)"
    >
      <ActivityFormSidebar
        :user="form"
        ref="activityFormSidebar"
        @afterSubmit="$refs.ticket.getList()"
      />

      <RecentActivity
        ref="ticket"
        :userId="form.user_guid"
        :is-customer="true"
        ticket-id="0"
        titleClass="mb-2 label-header"
        @filter="$refs.activityFilterSidebar.show()"
        @addActivity="$refs.activityFormSidebar.show()"
        @afterSubmit="(val) => $refs.ticket.getList(val)"
      />
    </template>

    <b-modal
      size="md"
      centered
      title="Point Adjust"
      v-model="isPointModal"
      hide-header-close
      @hidden="clearValue()"
      @ok="addPoint"
      ok-title="Add Point"
      footer-class="content-between"
    >
      <table class="w-100" id="point-table">
        <thead>
          <th class="text-center w-2 py-2">Point</th>
          <th class="text-center w-2 py-2">Expire Date</th>
          <th class="w-1"></th>
        </thead>
        <tbody>
          <template v-if="pointDetail.length > 0">
            <tr
              v-for="(items, index) of pointDetail"
              :key="items.id + '-point'"
            >
              <td class="text-center w-2 py-2">
                <template v-if="isEdit && index == isIndexEdit">
                  <InputText
                    textFloat=""
                    placeholder=""
                    class="mb-0"
                    type="number"
                    v-model="formEditPoint.point"
                    name="add-point"
                  />
                </template>

                <template v-else>
                  {{ items.point | numeral("0,0") }}
                </template>
              </td>
              <td class="text-center w-2 py-2">
                <div>
                  <template v-if="isEdit && index == isIndexEdit">
                    <InputDatePickerFilter
                      style="margin-bottom: 0px !important"
                      textFloat=""
                      name="point-expire-date"
                      ref="point-expire-date"
                      :value="formEditPoint.expired_date"
                      @input="(val) => (formEditPoint.expired_date = val)"
                    />
                  </template>
                  <div v-else>
                    <span v-if="items.expired_date" class="ml-auto">
                      {{
                        $moment(items.expired_date).format("DD MMM YYYY")
                      }}</span
                    >
                    <span v-else class="ml-auto">-</span>
                  </div>
                </div>
              </td>
              <td class="w-1">
                <div v-if="isEdit && index == isIndexEdit" class="ml-2">
                  <font-awesome-icon
                    icon="check-circle"
                    class="text-success cursor-pointer mx-3"
                    title="Ok"
                    @click="editModalPoint()"
                  />
                  <!-- <b-button variant="custom-primary" class="mx-2">Ok </b-button> -->

                  <font-awesome-icon
                    icon="times-circle"
                    class="text-danger cursor-pointer mr-2"
                    title="cancel"
                    @click="isEdit = false"
                  />
                </div>
                <div v-else class="ml-5">
                  <font-awesome-icon
                    icon="pencil-alt"
                    class="text-warning cursor-pointer mx-3"
                    title="Edit"
                    @click="editModalPoint(items, index)"
                  />
                  <font-awesome-icon
                    icon="trash-alt"
                    title="Edit"
                    class="text-danger cursor-pointer mr-2"
                    @click="deletePoint(items)"
                  />
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr class="bg-light">
              <td colspan="3" class="text-center py-2">No data</td>
            </tr>
          </template>
        </tbody>
      </table>
    </b-modal>
    <b-modal
      size="md"
      centered
      title="Add Point"
      v-model="isAddPoint"
      @hidden="clearValue()"
      ok-title="Confirm"
      hide-header-close
      footer-class="content-between"
      @ok="addPoint"
    >
      <InputSelect
        title="Branch"
        name="branch"
        class="mt-2"
        :isValidate="$v.formAddPoint.branch_id.$error"
        v-bind:options="[]"
        v-model="formAddPoint.branch_id"
        :v="$v.formAddPoint.branch_id"
        valueField="id"
        textField="name"
      >
        <template v-slot:option-first>
          <b-form-select-option value="" disabled
            >-- Select Branch --</b-form-select-option
          >
          <b-form-select-option value="0">Center Point</b-form-select-option>
        </template>
      </InputSelect>
      <InputText
        textFloat="Point"
        placeholder="Point"
        type="number"
        v-model="formAddPoint.update_point"
        name="add-point"
      />
      <InputDatePickerFilter
        class="ml-auto mb-0"
        textFloat="Expire Date"
        name="point-expire-date"
        ref="point-expire-date"
        :value="formAddPoint.expired_date"
        @input="(val) => (formAddPoint.expired_date = val)"
      />
      <div>
        <InputTextArea
          :rows="4"
          textFloat="Note"
          placeholder="Note"
          id="note1"
          v-model="formAddPoint.note"
        />
      </div>
    </b-modal>
    <DocumentView :form="form" ref="documentView" @getData="$emit('fetch')" />
  </div>
</template>

<script>
import RecentActivity from "@/components/ticket/activity/RecentActivity";
import ActivityFormSidebar from "@/components/ticket/activity/ActivityFormSidebar";
import ActivityFilterSidebar from "@/components/ticket/activity/ActivityFilterSidebar";
import DocumentView from "./DocumentView";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
export default {
  props: {
    form: { required: true },
    branchList: { required: false },
  },
  components: {
    DocumentView,
    RecentActivity,
    ActivityFormSidebar,
    ActivityFilterSidebar,
  },
  mounted() {
    this.createTooltipRange("range", "rangeV", "range-recen-avg");
    this.createTooltipRange("range-freq", "rangeV-freq", "range-freq-avg");
    this.createTooltipRange("range-mon", "rangeV-mon", "range-mon-avg");
  },
  validations: {
    formAddPoint: {
      branch_id: {
        required,
      },
    },
  },
  computed: {},
  data() {
    return {
      isPointModal: false,
      pointDetail: [],
      isEdit: false,
      formEditPoint: {
        user_point_id: 1,
        expired_date: "",
        is_delete: 0,
        point: 0,
      },
      isIndexEdit: -1,
      isAddPoint: false,
      formAddPoint: {
        user_guid: this.$route.params.id,
        branch_id: 0,
        update_point: 0,
        update_by_user_guid: this.$route.params.id,
        update_type: 1,
        note: null,

        is_have_expired: 1,
        expired_date: null,
      },
      saveValue: {},
    };
  },
  methods: {
    documentsCount() {
      let key =
        this.form.company_information == "ไม่จดทะเบียน"
          ? "is_not_register"
          : "is_register";

      if (this.form.company_information == "ไม่จดทะเบียน") {
        key = "is_not_register";
      } else {
      }
      let list = this.form.documents_setting.filter(
        (el) => el[key] == 1 && el.id != 1
      );
      let document = this.form.documents.filter((el) => el.path);
      let other = document.filter((el) => el.document_type_id == 1);

      return list.length + other.length;
    },
    selectBestMulp(value) {
      switch (value) {
        case value < 30:
          return 0.25;

        case value < 40:
          return 0.17;

        default:
          return 0.15;
      }
    },
    showDocument() {
      this.$refs.documentView.show();
    },
    async pointModal() {
      this.isPointModal = true;
      this.getPoint();
    },
    createTooltipRange(input, label, avg) {
      const range = document.getElementById(input);

      const rangeV = document.getElementById(label);
      const rangeAvg = document.getElementById(avg);
      const avgValue = range.dataset.avg;

      const newValue = Number(
        ((range.value - range.min) * 100) / (range.max - range.min)
      );
      let avgPosition = Number(
        ((avgValue - range.min) * 100) / (range.max - range.min)
      );

      let mulp = this.selectBestMulp(newValue);
      let newPosition = 8 - newValue * mulp;
      if (newValue == 100) newPosition = -5;
      if (newValue == 0) newPosition = 5;

      rangeV.innerHTML = `<span>${
        range.value < range.min ? 0 : range.value.toLocaleString()
      }</span>`;
      rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`;
      rangeAvg.style.left = `${avgPosition}%`;
    },
    async editModalPoint(val, index) {
      if (this.isEdit) {
        if (
          this.saveValue.point != this.formEditPoint.point ||
          this.$moment(this.saveValue.expired_date).format("YYYY-MM-DD") !=
            this.$moment(this.formEditPoint.expired_date).format("YYYY-MM-DD")
        ) {
          this.$bus.$emit("showLoading");
          let payload = {
            ...this.formAddPoint,
            update_point: this.formEditPoint.point,
            user_point_id: this.formEditPoint.id,
            update_type: 3,
            is_delete: 0,
            branch_id: val ? val.branch_id : this.formEditPoint.branch_id,
            user_guid: this.$route.params.id,
            expired_date: this.formEditPoint.expired_date
              ? this.$moment(this.formEditPoint.expired_date).format(
                  "YYYY-MM-DD"
                )
              : null,
          };
          await this.axios.put("/Customer/update_customer_point", payload);
          this.$emit("fetch");
          this.getPoint();
          this.$bus.$emit("hideLoading");
        }
        this.isEdit = false;
      } else {
        this.formEditPoint = val;
        this.saveValue = JSON.parse(JSON.stringify(val));
        this.isEdit = true;
        this.isIndexEdit = index;
      }
    },
    async deletePoint(val) {
      this.$bus.$emit("showLoading");
      let payload = {
        user_point_id: val.id,
        is_delete: 1,
        branch_id: val.branch_id,
        user_guid: this.$route.params.id,
        expired_date: val.expired_date
          ? this.$moment(val.expired_date).format("YYYY-MM-DD")
          : null,
      };
      await this.axios.post("/customer/edit/point", payload);
      this.$emit("fetch", true);
      this.getPoint();
      this.$bus.$emit("hideLoading");
    },
    async getPoint() {
      const resp = await this.axios(
        `/customer/GetAllPoint/${this.$route.params.id}`
      );
      this.pointDetail = resp.data.detail;
    },
    async addPoint(e) {
      if (this.isAddPoint) {
        this.$bus.$emit("showLoading");
        this.formAddPoint.expired_date = this.formAddPoint.expired_date
          ? this.$moment(this.formAddPoint.expired_date).format("YYYY-MM-DD")
          : null;
        const result = await this.axios.put(
          "/Customer/update_customer_point",
          this.formAddPoint
        );

        this.$emit("fetch", true);
        this.getPoint();
        this.$bus.$emit("hideLoading");
      } else {
        if (this.isEdit) {
          return e.preventDefault();
        }
        e.preventDefault();
        this.isAddPoint = true;
      }
    },
    clearValue() {
      this.isAddPoint = false;
      this.isEdit = false;
      this.formAddPoint = {
        user_guid: this.$route.params.id,
        branch_id: 0,
        update_point: 0,
        update_by_user_guid: this.$route.params.id,
        update_type: 1,
        note: null,

        is_have_expired: 1,
        expired_date: null,
      };
      this.$v.formAddPoint.$reset();
      this.saveValue = null;
    },
    countDuration(key) {
      let m = this.$moment(this.form[key]);
      let year, month, day, hour;
      if (key == "expire_date") {
        year = this.$moment(this.form[key]).diff(this.$moment(), "year");
        m.add(year, "year");
        month = m.diff(this.$moment(), "month");
        day = this.$moment(this.form[key]).diff(this.$moment(), "day");
        hour = this.$moment(this.form[key]).diff(this.$moment(), "hour");
      } else {
        year = this.$moment().diff(this.$moment(this.form[key]), "year");
        m.add(year, "year");
        month = this.$moment().diff(m, "month");
        day = this.$moment().diff(this.$moment(this.form[key]), "day");
        hour = this.$moment().diff(this.$moment(this.form[key]), "hour");
        // day = this.$moment(this.form[key]).diff(this.$moment(), "day");
      }

      if (year > 0 && month > 0)
        return `(${year}Y ${this.$moment(month, "M").format("M")}M ${
          key == "expire_date" ? "Remaining" : "Ago"
        })`;
      else if (year > 0 && month <= 0)
        return `(${year}Y ${key == "expire_date" ? "Remaining" : "Ago"})`;
      else if (year <= 0 && month > 0)
        return `(${this.$moment(month, "M").format("M")}M ${
          key == "expire_date" ? "Remaining" : "Ago"
        })`;
      else if (month <= 0 && day > 0)
        return `(${day}D ${key == "expire_date" ? "Remaining" : "Ago"})`;
      else if (day <= 0)
        return `(${hour}H ${key == "expire_date" ? "Remaining" : "Ago"})`;
      else return ``;
    },
  },
};
</script>

<style lang="scss">
.range-wrap {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}
.range-value {
  position: absolute;
  top: -50%;
}
.range-value span {
  min-width: 30px;
  padding: 0 3px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  background: var(--primary-color);
  color: #fff;
  font-size: 11px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 6px;
  top: -20px;
}
.range-value span:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 5px solid var(--primary-color);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}
.range-avg {
  height: 10px;
  width: 10px;
  box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.24);
  border-radius: 50%;
  background: #cbc1c1;
  z-index: 1;
  -webkit-appearance: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
}
.range-avg:before {
  color: rgba(42, 0, 193, 0.182);
  content: "Average (" attr(avg) ") ";
  position: absolute;
  width: auto;
  font-size: 10px;
  color: #bfbfbf;
  height: 20px;
  white-space: nowrap;
  top: 100%;
  left: 50%;
  transform: translate(-30%, 100%);
  margin-left: -5px;
  margin-top: -1px;
}
.range-avg:after {
  content: "";
  height: 17px;
  border-left: thin dashed;
  position: absolute;
  overflow: hidden;
  top: 100%;
  border-color: rgb(61 61 61 / 25%);
  left: 50%;
  transform: translate(-50%, 15%);
}
input[type="range"] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: var(--primary-color);
  border-radius: 25px;
}
input[type="range"]::-webkit-slider-thumb {
  height: 10px;
  width: 10px;
  position: relative;
  z-index: 2;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  background: #fff;
  -webkit-appearance: none;
  margin-top: -3px;
}
.customer-more-detail {
  row-gap: 2.5px;
  div {
    div:first-child {
      white-space: nowrap;
      color: rgb(159, 159, 159);
      // font-size: 12px;
    }
  }
}

table#point-table td,
th {
  text-align: right;
}
.line-height-1 {
  line-height: 1rem;
}
</style>
